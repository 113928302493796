import React from "react"
import styled from "styled-components"
import { HEADER_BG } from "../constants"
import { FlexContainer } from "../styles/layout.styled"
import LayoutRoot from "../components/LayoutRoot"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Image from "gatsby-image"
import { blogCreatedAtFormat, capitalizeWords } from "../utils"
import { options } from "./Blocks"
import Seo from "../components/seo"

const BlogContainer = styled(FlexContainer)`
  flex-direction: column;
  background: ${({ theme }) => theme.white};
  padding-bottom: 60px;
`

const BlogImageCover = styled(Image)`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 5.1;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    aspect-ratio: 3.1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    aspect-ratio: 2.1;
  }
`

const Title = styled.h1`
  font-size: 45px;
  margin: 0;
  color: #122436;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 24px;
  }
`

const BlogTitleContainer = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => `${theme.lightBlue}33`};
  padding: 47px 0 30px;
`

const AuthorName = styled.p`
  font-size: 18px;
  margin: 0 30px 0 0;
  line-height: 21px;
  color: ${({ theme }) => theme.lightBlue};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 14px;
    margin: 0 10px 0 0;
  }
`
const CreatedAt = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => `${theme.lightBlue}CC`};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 14px;
  }
`

const RichTextContainer = styled(FlexContainer)`
  color: ${({ theme }) => `${theme.lightBlue}CC`};
`

const BlogTemplate = ({ pageContext }) => {
  const content = pageContext.content

  return (
    <LayoutRoot background={HEADER_BG} withFooter>
      <Seo title={capitalizeWords(content?.blogTitle)} path={pageContext.pagePath} />
      <BlogImageCover
        loading="auto"
        fadeIn
        fluid={content?.coverImage?.fluid}
      />
      <BlogContainer tabletPadding="0 20px">
        <BlogTitleContainer
          maxWidth="765px"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Title>{content?.blogTitle}</Title>
          <FlexContainer margin="19px 0 0" justifyContent="flex-start">
            <AuthorName>Written by {content?.authorName}</AuthorName>
            <CreatedAt>{blogCreatedAtFormat(content?.createdAt)}</CreatedAt>
          </FlexContainer>
        </BlogTitleContainer>
        <RichTextContainer
          maxWidth="765px"
          flexDirection="column"
          alignItems="flex-start"
        >
          {renderRichText(content?.blogBody, options)}
        </RichTextContainer>
      </BlogContainer>
    </LayoutRoot>
  )
}

export default BlogTemplate
